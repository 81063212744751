<!--
* 创建人：罗兴
* 日 期：
* 描 述：勤工助学岗位管理
-->
<!--view 模板-->
<template>
  <div class="container">
    <!--=====================================顶部标题======================================-->
    <div class="page-title">勤工助学岗位申请</div>
    <!--=====================================工具栏========================================-->
    <div class="page-tool">
      <div>
        <span class="mr5 fcolor42">学年:</span>
        <el-select v-model="xn" size="small" clearable>
          <el-option
            v-for="(item, index) in xnList"
            :key="index"
            :label="item.ksnf + '-' + item.jsnf + '学年'"
            :value="item.ksnf + '-' + item.jsnf + '学年'"
          ></el-option>
        </el-select>
        <span class="mr5 ml20 fcolor42">岗位名称:</span>
        <el-input
          v-model="keyword"
          placeholder="请输入岗位名称"
          size="small"
        ></el-input>
        <el-button
          type="primary"
          icon="el-icon-search"
          size="small"
          @click="getDataPageList"
          >搜索</el-button
        >
      </div>

      <el-button-group>
        <el-button
          size="small"
          icon="el-icon-refresh"
          @click="reload()"
        ></el-button>
        <el-button
          size="small"
          v-if="hasPerms('sy_add')"
          icon="el-icon-plus"
          @click="showAdd"
          >新增</el-button
        >
        <el-button
          size="small"
          v-if="hasPerms('sy_edit')"
          icon="el-icon-box"
          @click="showEdit"
          >编辑</el-button
        >
        <el-button
          size="small"
          v-if="hasPerms('sy_delete')"
          icon="el-icon-delete"
          @click="del"
          >删除</el-button
        >
      </el-button-group>
    </div>
    <!--=============================表格========================================-->
    <div class="c_table">
      <el-table
        v-loading="tableloading"
        :data="dataPageList"
        :height="windowHeight - 270"
        border
        style="width: 100%"
        header-cell-class-name="tableStyle"
        highlight-current-row
        @current-change="currentRowChange"
        :row-style="{ height: '20px' }"
        :cell-style="{ padding: '5px' }"
      >
        <el-table-column prop="gwmc" label="岗位名称"> </el-table-column>
        <el-table-column prop="xn" label="学年"> </el-table-column>
        <el-table-column prop="xqrs" label="需求人数"> </el-table-column>
        <el-table-column prop="gwfzr" label="岗位负责人"> </el-table-column>
        <el-table-column prop="lxdh" label="联系电话"> </el-table-column>
        <el-table-column prop="spzt" label="审批状态">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.spzt == 0">待审批</el-tag>
            <el-tag v-if="scope.row.spzt == 1" type="success">审批通过</el-tag>
            <el-tag v-if="scope.row.spzt == 2" type="warning"
              >审批不通过</el-tag
            >
          </template>
        </el-table-column>
      </el-table>
      <!--=======================================分页================================================-->
      <div class="pagebox">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="pagination.page"
          :page-size="pagination.rows"
          layout="total, prev, pager, next, jumper"
          :total="pagination.records"
        >
        </el-pagination>
      </div>
    </div>
    <!------------------添加 修改 -------------->
    <el-dialog
      :title="operation == 1 ? '新增' : '编辑'"
      :visible.sync="dialogFormVisible"
      width="50%"
      :close-on-click-modal="false"
      top="5vh"
      @close="closeDialog"
    >
      <el-form
        :model="forminfo"
        v-if="dialogFormVisible"
        ref="formRef"
        :rules="infoRules"
      >
        <div class="form-item-group">
          <i class="fa fa-window-restore"></i>
          <div>基础信息</div>
        </div>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="学年" label-width="120px" prop="XN">
              <el-select
                v-model="forminfo.XN"
                placeholder="请选择学年"
                disabled
                style="display: block"
              >
                <el-option
                  v-for="(item, index) in xnList"
                  :key="index"
                  :label="item.ksnf + '-' + item.jsnf + '学年'"
                  :value="item.ksnf + '-' + item.jsnf + '学年'"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="单位" label-width="120px" prop="BMBH">
              <el-select
                v-model="forminfo.BMBH"
                placeholder="请选择单位"
                style="display: block"
              >
                <el-option
                  v-for="(item, index) in dwList"
                  :key="index"
                  :label="item.bmmc"
                  :value="item.bmbh"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="岗位名称" label-width="120px" prop="GWMC">
              <el-input
                v-model="forminfo.GWMC"
                autocomplete="off"
                placeholder="岗位名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="需求人数" label-width="120px" prop="XQRS">
              <el-input-number
                v-model="forminfo.XQRS"
                :min="1"
                label="申请人数"
              ></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
              label="申请开始时间"
              label-width="120px"
              prop="SQKSSJ"
            >
              <el-date-picker
                v-model="forminfo.SQKSSJ"
                type="date"
                align="right"
                placeholder="选择日期"
                style="width: 100%"
                value-format="yyyy-MM-dd"
              >
                >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="申请结束时间"
              label-width="120px"
              prop="SQJSSJ"
            >
              <el-date-picker
                v-model="forminfo.SQJSSJ"
                type="date"
                align="right"
                placeholder="选择日期"
                style="width: 100%"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
              label="工作开始时间"
              label-width="120px"
              prop="SQKSSJ"
            >
              <el-date-picker
                v-model="forminfo.GZKSSJ"
                type="date"
                align="right"
                placeholder="选择日期"
                style="width: 100%"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="工作结束时间"
              label-width="120px"
              prop="GZJSSJ"
            >
              <el-date-picker
                v-model="forminfo.GZJSSJ"
                type="date"
                align="right"
                placeholder="选择日期"
                style="width: 100%"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="岗位描述" label-width="120px" prop="GWMS">
          <el-input
            v-model="forminfo.GWMS"
            autocomplete="off"
            type="textarea"
            :rows="5"
            placeholder="记录谈话/谈心或心理辅导的原因、内容和发现的问题、拟采取措施等"
          ></el-input>
        </el-form-item>
        <el-form-item label="岗位要求" label-width="120px" prop="GWYQ">
          <el-input
            v-model="forminfo.GWYQ"
            autocomplete="off"
            type="textarea"
            :rows="5"
            placeholder="栏请注明岗位对学生的具体要求，如性别、专业、特长等"
          ></el-input>
        </el-form-item>
        <el-form-item label="申请理由" label-width="120px" prop="GWYQ">
          <el-input
            v-model="forminfo.SQLY"
            autocomplete="off"
            type="textarea"
            :rows="5"
            placeholder="申请理由"
          ></el-input>
        </el-form-item>
        <div class="form-item-group">
          <i class="fa fa-window-restore"></i>
          <div>联系信息</div>
        </div>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="负责人" label-width="120px" prop="GWFZR">
              <el-input
                v-model="forminfo.GWFZR"
                autocomplete="off"
                placeholder="岗位负责人"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系电话" label-width="120px" prop="LXDH">
              <el-input
                v-model="forminfo.LXDH"
                autocomplete="off"
                placeholder="联系电话"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer" v-if="forminfo.SPZT != 1">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveForm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<!-- javascript脚本-->
<script>
import {
  getdwgwsqpagelist,
  getgwsqformpagelist,
  delgwsq,
  savegwsq,
} from '../../api/qgzx'
import { GetListByQX } from '../../api/jcxx'
import {
  getAuthorizeButtonColumnList,
  getXNList,
  getDQXQ,
} from '../../api/system'
export default {
  data() {
    return {
      active: 0,
      pagination: {
        rows: 10,
        page: 1,
        sord: 'DESC',
        records: 0,
        total: 0,
      },
      windowHeight: document.documentElement.clientHeight, // 实时屏幕高度
      xn: '',
      bmbh: '',
      id: '',
      keyword: '',
      keyValue: '',
      tableloading: true,
      buttonauth: {},
      dataPageList: [],
      formdata: {},
      operation: 1, // 当前操作1=添加，0=编辑
      dialogFormVisible: false, // 是否显示弹出框
      xnList: [],
      dwList: [],
      forminfo: {
        ID: '',
        XN: '',
        BMBH: '',
        GWMC: '',
        XQRS: 0,
        GWFZR: '',
        LXDH: '',
        SQKSSJ: null,
        SQJSSJ: null,
        GZKSSJ: null,
        GZJSSJ: null,
        GWMS: '',
        GWYQ: '',
        SQLY: '',
        SPZT: 0,
        SPSJ: null,
        SPYJ: '',
      },
      infoRules: {
        XN: [{ required: true, message: '请选择学年', trigger: 'change' }],
        BMBH: [{ required: true, message: '请选择单位', trigger: 'blur' }],
        GWMC: [{ required: true, message: '请填写岗位名称', trigger: 'blur' }],
        XQRS: [
          { required: true, message: '请填写需求人数', trigger: 'change' },
        ],
        SQKSSJ: [
          { required: true, message: '请选择申请开始时间', trigger: 'blur' },
        ],
        SQJSSJ: [
          { required: true, message: '请选择需求结束时间', trigger: 'blur' },
        ],
        GZKSSJ: [
          { required: true, message: '请选择工作开始时间', trigger: 'change' },
        ],
        GZJSSJ: [
          { required: true, message: '请选择工作结束时间', trigger: 'blur' },
        ],
        GWMS: [{ required: true, message: '请填写岗位描述', trigger: 'blur' }],
        GWYQ: [
          { required: true, message: '请填写岗位要求', trigger: 'change' },
        ],
        GWFZR: [
          { required: true, message: '请填写岗位负责人', trigger: 'blur' },
        ],
        LXDH: [{ required: true, message: '请填写联系电话', trigger: 'blur' }],
        SQLY: [{ required: true, message: '请填写申请理由', trigger: 'blur' }],
      },
    }
  },
  watch: {},
  created() {
    getAuthorizeButtonColumnList({ url: this.$route.path }).then((res) => {
      if (res.code === 200) {
        this.buttonauth = res.data.btns
        console.log(res.data.btns)
      } else {
        this.buttonauth = {}
      }
    })
    GetListByQX().then((res) => {
      if (res.code === 200) {
        this.dwList = res.data
      }
    })
    getXNList().then((res) => {
      if (res.code === 200) {
        this.xnList = res.data
        getDQXQ().then((res) => {
          if (res.code === 200) {
            this.xn = res.data.xnmc
            this.getDataPageList()
          }
        })
      }
    })
  },
  methods: {
    // 判断是否有该按钮的权限
    hasPerms(code) {
      if (Object.prototype.hasOwnProperty.call(this.buttonauth, code)) {
        return true
      } else {
        return false
      }
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.pagination.page = val
      this.getDataPageList()
    },
    reload() {
      this.$router.go(0)
    },
    // 当前行发生变化
    selectRowChange(rows) {
      this.ids = null
      this.ids = rows.map((v) => {
        return v.id
      })
    },
    // 获取分页信息
    getDataPageList() {
      this.tableloading = true
      getdwgwsqpagelist({
        queryJson: JSON.stringify({
          xn: this.xn,
          bmbh: this.bmbh,
          gwmc: this.keyword,
        }),
        pagination: JSON.stringify(this.pagination),
      })
        .then((res) => {
          if (res.code === 200) {
            this.tableloading = false
            this.pagination.page = res.data.page
            this.pagination.total = res.data.total
            this.pagination.records = res.data.records
            this.dataPageList = res.data.rows
          } else {
            this.tableloading = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 显示添加弹出框
    showAdd() {
      this.operation = 1
      this.keyValue = ''
      getDQXQ().then((res) => {
        if (res.code === 200) {
          this.forminfo.XN = res.data.xnmc
        }
      })
      this.dialogFormVisible = true
    },
    // 显示编辑弹出框
    showEdit() {
      if (this.id === '') {
        this.$message.error('您没有选中任何数据项，请选中后在操作！')
        return 0
      } else {
        this.operation = 0
        getgwsqformpagelist({ id: this.id }).then((res) => {
          if (res.code === 200) {
            this.forminfo.ID = res.data.id
            this.forminfo.XN = res.data.xn
            this.forminfo.BMBH = res.data.bmbh
            this.forminfo.GWMC = res.data.gwmc
            this.forminfo.XQRS = res.data.xqrs
            this.forminfo.GWFZR = res.data.gwfzr
            this.forminfo.LXDH = res.data.lxdh
            this.forminfo.SQKSSJ = res.data.sqkssj
            this.forminfo.SQJSSJ = res.data.sqjssj
            this.forminfo.GZKSSJ = res.data.gzkssj
            this.forminfo.GZJSSJ = res.data.gzjssj
            this.forminfo.GWMS = res.data.gwms
            this.forminfo.GWYQ = res.data.gwyq
            this.forminfo.SQLY = res.data.sqly
            this.forminfo.SPZT = res.data.spzt
            this.forminfo.SPSJ = res.data.spsj
            this.forminfo.SPYJ = res.data.spyj
            this.dialogFormVisible = true
          } else {
            this.$message.error(res.message)
          }
        })
      }
    },
    // 保存信息
    saveForm() {
      if (this.operation === 1) {
        this.id = ''
        this.forminfo.ID = ''
      } else {
        this.forminfo.ID = this.id
      }
      this.$refs.formRef.validate(async (valid) => {
        if (!valid) {
          return false
        } else {
          savegwsq(this.forminfo).then((res) => {
            if (res.code === 200) {
              this.$message.success(res.message)
              this.dialogFormVisible = false
              this.getDataPageList()
              this.$refs.formRef.resetFields()
            } else {
              this.$message.error(res.message)
            }
          })
        }
      })
    },
    del() {
      if (!this.id) {
        this.$message.error('您没有选中任何数据项，请选中后在操作！')
        return 0
      } else {
        delgwsq({ id: this.id }).then((res) => {
          if (res.code === 200) {
            this.$message.success(res.message)
            this.getDataPageList()
          } else {
            this.$message.error(res.message)
          }
        })
      }
    },
    // 当添加/编辑弹出框关闭时调用
    closeDialog() {
      this.active = 0
      this.$nextTick(() => {
        this.frominfo = JSON.parse(JSON.stringify(this.formdata))
      })
      this.$refs.formRef.resetFields()
    },
    // 当前行发生变化
    currentRowChange(currentRow) {
      if (!currentRow) {
        this.id = ''
      } else {
        this.id = currentRow.id
      }
    },
  },
}
</script>
<!-- CSS样式 -->
<style scoped>
.container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden;
}
.page-title {
  height: 32px;
  line-height: 32px;
  border-bottom: 1px solid #ccc;
  color: #666;
  font-weight: bold;
  padding-left: 9px;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
}
.page-tool {
  padding: 20px;
  width: 100%;
  height: 70px;
  border-bottom: 1px solid #ccc;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.page-tool .el-input {
  width: 300px;
}

.page-tool .el-button {
  margin-left: 10px;
}
.c_table {
  height: 100%;
  overflow-y: auto;
}
.el-table >>> .tableStyle {
  background-color: #f8f8f8 !important;
  color: #333;
  font-weight: 400;
  padding: 5px 0;
}
.form-item-group {
  display: flex;
  color: #409eff;
  align-items: center;
  font-size: 16px;
  margin-left: 20px;
  margin-bottom: 20px;
}
.form-item-group i {
  margin-right: 5px;
}
</style>
